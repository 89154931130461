<template>
  <Welcome v-if="status==='not-started'" :initData="initData"></Welcome>
  <FirstStep v-if="status==='first-step'" :initData="initData"></FirstStep>
  <Characters v-if="status==='characters'" :initData="initData"></Characters>
  <Question v-if="status==='question'" :initData="initData"></Question>
  <StoryEnd v-if="status==='story-end'" :initData="initData"></StoryEnd>
</template>

<script>
import Welcome from "@/views/Welcome"
import FirstStep from "@/views/FirstStep"
import Characters from "@/views/Characters"
import Question from "@/views/Question"
import StoryEnd from "@/views/StoryEnd"

export default {
  name: 'Game',
  props: ['initData'],
  components: {
    Welcome, FirstStep, Characters, Question, StoryEnd
  },
  data(){


    return {

    }
  },
  computed:{
    status(){
      return this.initData.user.status
    }
  },
  methods:{
    restart(e){
      localStorage.removeItem('sez_session_hash')
      e.initData.user.status = 'not-started'
      e.initData.user.firstStep.step = 1
      e.initData.user.properties = null
      e.initData.user.questionData = null
      e.initData.user.parameters = {
        p1 : 0,
        p2 : 0,
        p3 : 0
      }
      e.initData.user.otherParameters = null

      let audioSrc = ''
      if(isLocalHost===true){
        audioSrc = 'audio/welcome/Violin_Huasteco.mp3'
      }else{
        audioSrc = '/app/audio/welcome/Violin_Huasteco.mp3'
      }
      console.log('restart: '+audioSrc)

      e.initData.audio.play = audioSrc
    }
  }
}
</script>
<style>
</style>