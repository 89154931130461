<template>
  <footer>
    <div class="container">
      <div class="copyright">
          <img src="../assets/images/nka_2023.png" alt="Nemzeti Kultúrális Alap"/>
          © Csongrádi Információs Központ Tari László Múzeum
      </div>
      <!-- Ha nincs szükség rá, ki lehet venni -->
      <!-- <ul class="d-flex flex-column small">
        <li class=""><router-link to="/">Használati feltételek</router-link></li>
        <li class=""><router-link to="/">Adatkezelési tájékoztató</router-link></li>
      </ul> -->
      <div class="footer-menu">
        <button v-if="isTestSite" class="btn btn-info me-2" @click="Game.methods.restart(this.$parent)">Restart</button>
        <a href="/data/sar_es_tuz_impresszum_stablista.pdf" target="_blank" class="btn btn-info me-2">Impresszum</a>
        <a href="/data/sar_es_tuz_jatekszabalyzat.pdf" target="_blank" class="btn btn-info">Játékszabály</a>
      </div>
    </div>
  </footer>
</template>

<script>
import Game from '@/views/Game'

export default {
  name: 'Footer',
  components: {
    Game
  },
  data(){
    return{
      Game,
      isTestSite
    }
  }
}
</script>
<style scoped>
footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  background-color: var(--light-brown-color);
  color: var(--brown-color);
}
.container{
  max-width: 100%;
  padding: 26px 20px 22px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-info{
  color: var(--brown-color);
  border: 3px solid var(--brown-color);
  border-radius: 9px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  background-color: var(--light-brown-color);
}
.btn-info:hover{
  color: var(--light-brown-color);
  border: 3px solid var(--brown-color);
  background-color: var(--brown-color);
}
.copyright img{
    max-width: 100px;
}
@media (max-width: 992px) {
  footer{
    height: auto;
  }
  .container{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
}
</style>