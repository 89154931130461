<template>
  <div class="d-flex guidance">
    <h1>Válassz egy karaktert!<br>
      <span class="small">A választott kártyáid alapján ez(ek) a Te karaktere(i)d. Válassz ki egyet, vagy egy teljesen másikat, és nyomj a tovább gombra</span>
    </h1>
  </div>
  <div class="container">
    <div class="card-holder">
      <div class="card" v-for="character in characters" :data-id="character.id" :data-positive="character.positiveId" :data-negative="character.negativeId" @click="selectCard">
        <img class="card-side front" :src="imagePath+character.id+'.png'" :alt="character.name_hu"/>
        <img class="card-side back" src="../assets/images/cards/character.png" alt="Karakter kártya hátsó része" />
        <div class="full-name">
          {{character.fullName_hu}}
        </div>
        <div class="properties">
          <div class="top">
            <span>{{character.negative_hu}}</span> <img src="../assets/images/bones.svg" alt=""/>
          </div>
          <div class="bottom">
            <span>{{character.positive_hu}}</span> <img src="../assets/images/heart.svg" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" v-if="selectedCard" @click="next">Tovább</button>
  </div>

</template>

<script>
import {Response} from "@/classes/response";

export default {
  name: 'Characters',
  props: ['initData'],
  data(){
    let characters = this.initData.characters
    let imagePath = ''
    if(isLocalHost===true){
      imagePath = '/img/characters/'
    }else{
      imagePath = '/app/img/characters/'
    }

    return {
      characters,
      imagePath,
      selectedCard : false
    }
  },
  mounted() {
    this.selectByUserDecision()
    this.cardAnimation()
  },
  methods:{
    selectByUserDecision(){
      setTimeout(() => {
        let userDecision = this.initData.user.properties
        let userPositive = userDecision.positive
        let userNegative = userDecision.negative
        let cardElements = document.querySelectorAll('.card')
        for (let i = 0; i < cardElements.length; i++) {
          let cardElement = cardElements[i]
          let cardPositive = cardElement.dataset.positive
          let cardNegative = cardElement.dataset.negative
          if (cardPositive == userPositive || cardNegative == userNegative) {
            cardElement.classList.add('selected')
            this.selectedCard = true
          }
        }
      },5*600)
    },
    selectCard(e){
      //RESET ALL CARD
      let cardElements = document.querySelectorAll('.card')
      for(let i = 0;i<cardElements.length;i++){
        cardElements[i].classList.remove('selected')
      }
      //SELECT OR UNSELECT CARD
      let cardElement =  e.target

      while(!cardElement.classList.contains('card')){
        cardElement = cardElement.parentNode
      }

      cardElement.classList.add('selected')
      this.selectedCard = true
    },
    next(){
      let selectedId = null
      let cardElements = document.querySelectorAll('.card')
      for(let i = 0;i<cardElements.length;i++){
        let cardElement = cardElements[i]
        if(cardElement.classList.contains('selected')){
          selectedId = cardElement.dataset.id
        }
      }

      let formData = new FormData()
      formData.append('card_id', selectedId)

      this.initData.loader = true

      this.$axios.post('/api/v1/user/character',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              window.scrollTo(0,0);

              console.log(responseData)
              this.initData.user.status = responseData.data.status
              this.initData.user.firstStep.step = null
              this.initData.user.questionData = responseData.data.questionData
              this.initData.user.parameters = responseData.data.parameters
              this.initData.user.counter = responseData.data.counter
              this.initData.user.characterId = responseData.data.characterId
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    },
    cardAnimation(){
      let cards = document.querySelectorAll('.card')
      setTimeout(() => {
        let cardLength = cards.length
        for(let i = 0; i<cardLength;i++){
          let card = cards[i]
          if(card.classList.contains('reverse')===true){
            continue
          }else{
            card.classList.add('reverse')
            if(i<(cardLength-1)){
              this.cardAnimation()
              break
            }
          }
        }
      }, 400)
    }
  }
}
</script>
<style scoped>
.container{
  max-width: 1460px;
  padding-bottom: 300px;
}
.card-holder{
  display: flex;
  column-gap: 22px;
}
.guidance{
  column-gap: 16px;
  padding-top: 1.5rem;
  justify-content: center;
  margin: -140px auto 116px;
}
.guidance::before{
  content: '';
  background:url(../assets/images/pattern.svg);
  width: 15px;
  height: 20px;
  margin-top: 10px;
}
.guidance::after{
  content: '';
  background:url(../assets/images/pattern.svg);
  width: 15px;
  height: 20px;
  transform: rotate(180deg);
  margin-top: 10px;
}
.guidance h1{
  font-family: 'PTF 75',sans-serif;
  font-size: 1.666rem;
  line-height: 2rem;
}
.guidance h1 .small{
  display: block;
  max-width: 420px;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: var(--brown-color);
  text-align: justify;
}

.btn-primary{
  float: right;
  padding: 0 0.75rem;
}

.btn-primary::after{
  content: '';
  width: 30px;
  height: 30px;
  background-image: url('../assets/images/arrow.svg');
  background-repeat: no-repeat;
  background-position: center right;
  transform: rotate(180deg);
}

.card{
  border-radius: 26px;
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  top: 0;
  transition: all 0.8s ease;
}

.card-side {
  border-radius: 10%;
  transition: all 0.8s ease;
  backface-visibility: hidden;
  box-shadow: 16px 16px 22px 4px rgba(69, 119, 24, 0.35);
}

.card.selected{
  top: -30px;
}

.card.selected .card-side.front{
  box-shadow: 16px 16px 22px 4px rgb(230, 99, 70, 0.8);
}

.card .full-name{
  display: none;
  margin-top: 35px;
  font-family: 'PTF 75',sans-serif;
  text-align: center;
}

.card.selected .full-name{
  display:block;
}

.card .properties{
  display: none;
  font-family: 'PTF 55',sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  padding: 20px 30px 0 30px;
}

.card .properties .top,.card .properties .bottom{
  display: flex;
}

.card .properties .top{
  border-bottom: 2px solid #8e684f;
  padding-bottom: 15px;
}

.card .properties .bottom{
  padding-top: 15px;
}

.card .properties span{
  flex: 0 0 auto;
  width: 76%;
  padding-top: 10px;
  text-align: center;
}

.card .properties img{
  flex: 0 0 auto;
  width: 24%;
}

.card.selected .properties{
  display:block;
}

.card-side.back{
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card-side.front {
  transform: rotateY(-180deg);
}

.card.reverse .card-side.back {
  transform: rotateY(180deg);
}

.card.reverse .card-side.front {
  transform: rotateY(0deg);
}
@media (max-width: 992px) {
  .guidance{
    justify-content: right;
  }
}
@media (max-width: 768px) {
  .guidance{
    margin: 0 auto 70px;
    padding: 0 1rem;
  }
  .guidance.d-flex{
    display: block!important;
  }
}
@media (max-width: 576px) {
  .card-holder{
    display: block!important;
  }
  .card{
    width: auto;
    max-width: 400px;
    margin: 0 auto 2rem auto;
  }
  .card-side{
    width: 100%;
    max-width: 400px;
  }
  .card-side.back{
    max-width: 400px;
  }
}
</style>
