<template>
</template>

<script>
export default {
  name: 'Audio',
  props: ['initData'],
  data(){
    return{
      audio : null
    }
  },
  computed: {
    audioPlay(){
      return this.initData.audio.play
    },
    audioSrc(){
      return this.initData.audio.src
    },
    audioMuted(){
      return this.initData.audio.muted
    },
    audioLooped(){
      return this.initData.audio.loop
    }
  },
  watch:{
    audioPlay(){
      console.log('audio play watch')
      //IF NULL, AND IT IS PLAYING -> MUTE
      if(this.audioPlay===null){
        if(this.audio!==null){
          console.log('audio pause')
          this.audio.pause()
        }
      }
      //ELSE PLAY CHECK SAME AUDIO
      else {
        console.log(this.audioPlay)
        console.log(this.audioSrc)
        if(this.audioPlay!==this.audioSrc){
          if(this.audio!==null){
            this.audio.pause()
            console.log('pause '+this.audio)
          }


          this.audio = new Audio(this.audioPlay)
          if(this.audioMuted===false){
            this.audio.addEventListener("canplaythrough", (event) => {
              /* the audio is now playable; play it if permissions allow */
              this.audio.play();
              this.initData.audio.duration = this.audio.duration
            });
          }
          this.audio.loop = this.audioLooped
          console.log('play audio '+this.audioPlay)

          this.initData.audio.src = this.audioPlay
        }
      }
    },
    audioMuted(){
      console.log(this.audioMuted)
      if(this.audioMuted===true){
        if(this.audio!==null){
          console.log('mute')
          this.audio.pause()
        }
      }else{
        if(this.audio!==null){
          console.log('unmute')
          this.audio.play()
        }
      }
      localStorage.setItem('sez_audio_muted',this.audioMuted)
    }
  }
}
</script>

<style scoped>
</style>