<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-12">
        <h1>A történet végére értél.</h1>
<!--        <p>A játék még fejlesztés alatt áll. Kérlek, szánj pár percet arra, hogy elmondd a véleményedet! Töltsd ki az alábbi, kérdőívet!</p>-->
<!--        <p><a href="https://bit.ly/sarestuz_storygame" target="_blank">https://bit.ly/sarestuz_storygame</a></p>-->
        <form @submit.prevent="handleSubmit">
          <div class="mb-3">
            <label for="fullName" class="form-label">Becenév*</label>
            <input type="text" id="fullName" class="form-control" :class="nickNameErrorText ? 'is-invalid' : ''" v-model="nickName" maxlength="20" autofocus aria-describedby="nickNameHelp">
            <div id="nickNameHelp" class="form-text">Egy maximum 20 karakteres becenév megadásával feliratkozhatsz a ranglistára.</div>
            <div v-if="nickNameErrorText" class="small text-danger">
              {{ nickNameErrorText }}
            </div>
          </div>
          <div class="mb-4 form-check">
            <input type="checkbox" class="form-check-input h3" :class="newsletterErrorText ? 'is-invalid' : ''" id="newsletterCheck" v-model="newsletter">
            <label class="form-check-label small" for="newsletterCheck">Feliratkozom a Múzeum hírlevelére.</label>
          </div>
          <div v-if="newsletter">
            <div class="mb-3">
              <label for="fullName" class="form-label">Teljes név*</label>
              <input type="text" id="fullName" class="form-control" :class="fullNameErrorText ? 'is-invalid' : ''" v-model="fullName" maxlength="100">
              <div v-if="fullNameErrorText" class="small text-danger">
                {{ fullNameErrorText }}
              </div>
            </div>
            <div class="mb-3">
              <label for="userEmail" class="form-label">E-mail cím*</label>
              <input type="text" id="userEmail" class="form-control" :class="emailErrorText ? 'is-invalid' : ''" v-model="email" maxlength="150">
              <div v-if="emailErrorText" class="small text-danger">
                {{ emailErrorText }}
              </div>
            </div>
            <div class="mb-4 form-check">
              <input type="checkbox" class="form-check-input h3" :class="policyErrorText ? 'is-invalid' : ''" id="policyCheck" v-model="policy">
              <label class="form-check-label small" for="policyCheck">Feliratkozom a Múzeum hírlevelére és elfogadom az <a href="/data/sar_es_tuz_adatkezelesi_tajekoztato.pdf" target="_blank">adatvédelmi tájékoztatót</a>.</label>
              <div v-if="policyErrorText" class="small text-danger">
                {{ policyErrorText }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-12">
              <button class="btn btn-primary restart mb-3" @click="Game.methods.restart(this.$parent)">Újra játszom</button>
            </div>
            <div class="col-sm-6 col-12">
              <button type="submit" class="btn btn-primary form-control mb-3">Irány a ranglista!</button>
            </div>
          </div>
        </form>
        <div class="shares">
          <p class="browser-share" @click="browserShare">
            <img class="share-image" src="../assets/images/share.svg" alt="" />
              Oszd meg a játék linkjét!
          </p>
          <p>
            <a :href="characterUrl" target="_blank" rel="noreferrer">
              <img class="facebook-image" src="../assets/images/facebook.svg" alt="" />
              Ismerd meg a karakteredet!
            </a>
          </p>
          <p>
            <a href="http://www.konyvtar.csongrad.hu/" target="_blank" rel="noreferrer">
              <img class="facebook-image" src="../assets/images/museum.svg" alt="" />
              Meglátogatom a múzeumot!
            </a>
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <canvas id="resultChart" width="400" height="400"></canvas>
      </div>
      <div class="col-12">
        <div class="list-group list-group-horizontal" id="list-tab" role="tablist">
          <a class="list-group-item list-group-item-action active" id="list-ranking-list" data-bs-toggle="list" href="#list-ranking" role="tab" aria-controls="list-ranking">Havi ranglista</a>
          <a class="list-group-item list-group-item-action" id="list-fullRanking-list" data-bs-toggle="list" href="#list-fullRanking" role="tab" aria-controls="list-fullRanking">Örökös ranglista</a>
        </div>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="list-ranking" role="tabpanel" aria-labelledby="list-ranking-list">
            <div class="table-responsive mt-4">
              <table class="table ranking-table">
                <thead>
                <tr>
                  <th>Helyezés</th>
                  <th>Becenév</th>
                  <th>Becsület</th>
                  <th>Közösségi megítélés</th>
                  <th>Vagyon</th>
                </tr>
                </thead>
                <tbody>
                <tr height="10" colspan="5"></tr>
                <tr :class="rank.number>0 && rank.number<4 ? 'highlighted' : ''" v-for="rank in ranking">
                  <td><img class="medal" src="../assets/images/badge.svg" v-if="rank.number>0 && rank.number<4" alt=""/> {{rank.number}}.</td>
                  <td>{{rank.nickName}}</td>
                  <td>{{rank.p1}}%</td>
                  <td>{{rank.p2}}%</td>
                  <td>{{rank.p3}}%</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab-pane fade" id="list-fullRanking" role="tabpanel" aria-labelledby="list-fullRanking-list">
            <div class="table-responsive mt-4">
              <table class="table ranking-table">
                <thead>
                <tr>
                  <th>Helyezés</th>
                  <th>Becenév</th>
                  <th>Becsület</th>
                  <th>Közösségi megítélés</th>
                  <th>Vagyon</th>
                </tr>
                </thead>
                <tbody>
                <tr height="10" colspan="5"></tr>
                <tr :class="rank.number>0 && rank.number<4 ? 'highlighted' : ''" v-for="rank in fullRanking">
                  <td><img class="medal" src="../assets/images/badge.svg" v-if="rank.number>0 && rank.number<4" alt=""/> {{rank.number}}.</td>
                  <td>{{rank.nickName}}</td>
                  <td>{{rank.p1}}%</td>
                  <td>{{rank.p2}}%</td>
                  <td>{{rank.p3}}%</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import Game from '@/views/Game'
import {Response} from "@/classes/response";
import {Validate} from "@/classes/validate";

export default {
  name: 'StoryEnd',
  props: ['initData'],
  data(){
    let parameterLabels = this.initData.parameterLabels
    let userParameters = this.initData.user.parameters
    let otherParameters = this.initData.user.otherParameters

    return {
      parameterLabels,
      userParameters,
      otherParameters,
      Game,
      nickName : null,
      nickNameErrorText : false,
      fullName : null,
      fullNameErrorText : false,
      email : null,
      emailErrorText : false,
      newsletter : false,
      newsletterErrorText : false,
      policy : false,
      policyErrorText : false
    }
  },
  mounted() {
    this.playAudio()

    const ctx = document.getElementById('resultChart').getContext('2d')

    const myChart = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: this.parameterLabels,
        datasets: [{
          label: 'eredményem',
          data: [this.userParameters.p1,this.userParameters.p2,this.userParameters.p3],
          fill: true,
          backgroundColor: 'rgba(230, 99, 70, 0.2)',
          borderColor: 'rgb(230, 99, 70)',
          pointBackgroundColor: 'rgb(255, 99, 132)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(255, 99, 132)'
        }, {
          label: 'átlag',
          data: [this.otherParameters.p1Avg,this.otherParameters.p2Avg,this.otherParameters.p3Avg],
          fill: true,
          backgroundColor: 'rgba(141, 103, 80, 0.2)',
          borderColor: 'rgb(141, 103, 80)',
          pointBackgroundColor: 'rgb(54, 162, 235)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)'
        }]
      },
      options: {
        elements: {
          line: {
            borderWidth: 3
          }
        }
      }
    })
  },
  computed:{
    ranking(){
      return this.initData.user.ranking
    },
    fullRanking(){
      return this.initData.user.fullRanking
    },
    status(){
      return this.initData.user.status
    },
    characterId(){
      return this.initData.user.characterId
    },
    characterUrl(){
      if(this.characterId===1){
        return 'https://www.facebook.com/tari.laszlo.muzeum/posts/pfbid0iH7HkkZtKkqpq4te2pEH6Z1FszsEPzcVaG11RyWXtwfjJrLAJp2DBoiqHGamyXftl'
      }else if(this.characterId===2){
        return 'https://www.facebook.com/photo/?fbid=213050231287503&set=gm.2351822471661900&idorvanity=2338609756316505'
      }else if(this.characterId===3){
        return 'https://www.facebook.com/photo/?fbid=212354108023782&set=gm.2349501121894035&idorvanity=2338609756316505'
      }else if(this.characterId===4){
        return 'https://www.facebook.com/photo/?fbid=212798087979384&set=gm.2351004495077031&idorvanity=2338609756316505'
      }else if(this.characterId===5){
        return 'https://www.facebook.com/photo/?fbid=213050027954190&set=gm.2351821948328619&idorvanity=2338609756316505'
      }else{
        return ''
      }
    }
  },
  watch:{
    status(){
      this.playAudio()
    }
  },
  methods:{
    playAudio(){
      if(this.status==='story-end'){
        let audioSrc = ''
        if(isLocalHost===true){
          audioSrc = 'audio/questions/2_Nem_lehet_mar.mp3'
        }else{
          audioSrc = '/app/audio/questions/2_Nem_lehet_mar.mp3'
        }
        this.initData.audio.loop = true
        this.initData.audio.play = audioSrc
      }
    },
    handleSubmit(){
      let validate = new Validate()

      //NICKNAME
      this.nickNameErrorText = validate.emptyField(this.nickName, 'Becenév megadása kötelező.')

      //FULLNAME
      if(this.newsletter===true){
        this.fullNameErrorText = validate.emptyField(this.fullName, 'Teljes név megadása kötelező.')
      }

      //EMAIL
      if(this.newsletter===true) {
        this.emailErrorText = validate.emptyField(this.email, 'Nincs megadva e-mail cím.')
        if (this.emailErrorText === null) {
          this.emailErrorText = validate.email(this.email, 'E-mail cím nem megfelelő formátumú.')
        }
      }

      //POLICY
      if(this.newsletter===true) {
        this.policyErrorText = validate.checkboxChecked(this.policy, 'Elfogadása kötelező.')
      }

      if (this.nickNameErrorText || this.fullNameErrorText || this.emailErrorText || this.policyErrorText) {
        return false;
      }

      let formData = new FormData()
      formData.append('nick_name', this.nickName)
      formData.append('full_name', this.fullName)
      formData.append('email', this.email)
      formData.append('policy', this.policy)
      formData.append('newsletter', this.newsletter)

      this.initData.loader = true
      this.$axios.post('/api/v1/user/registration',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.initData.user.ranking = responseData.data.ranking
              this.initData.user.fullRanking = responseData.data.fullRanking
              this.nickName = null
              this.fullName = null
              this.email = null
              this.policy = false
              this.newsletter = false
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });

    },
    browserShare(){
      if (window.innerWidth < 960) {
        if (navigator.share) {
          navigator.share({
            title: 'Sár és tűz',
            text: 'Oszd meg ismerőseiddel és barátaiddal a Csongrád Múzeum Sár és tűz játékát.',
            url: window.location.href
          })
        }
      }else{
        navigator.clipboard.writeText(window.location.href)
        this.initData.alerts.push({msg: 'Link vágólapra másolva.', type: 'success'})
      }

    }
  }
}
</script>
<style scoped>
.form-check{
  padding-left: 2.5rem;
  padding-right: 0rem;
}
.form-check-label{
  line-height: 2.466rem;
}

form a, p a{
  color: var(--brown-color);
}

.shares a{
  color: var(--brown-color);
  text-decoration: none;
}

.share-image{
  width: 24px;
}

.facebook-image{
  width: 24px;
}

.btn-primary.restart::before{
  content: '';
  width: 30px;
  height: 30px;
  background-image: url('../assets/images/arrow.svg');
  background-repeat: no-repeat;
  background-position: center left;
}
.table-responsive{
  padding-bottom: 5rem;
}
.browser-share{
  cursor: pointer;
}
.list-group-item{
  padding: 0 1rem 0 0;
  background-color: rgba(0,0,0,0);
  border: none;
  font-size: 1rem;
  color: var(--brown-color);
}
.list-group-item.active,.list-group-item:hover{
  background-color: rgba(0,0,0,0);
  color: var(--orange-color);
}
.list-group-item-action{
  width: auto;
}
.ranking-table{
  border-collapse: separate;
  font-size: 0.625rem;
  border-spacing: 0;
  color: var(--brown-color);
}
.ranking-table thead th{
  padding: 14px;
  text-align: center;
}
.ranking-table thead th{
  border-top: 2px solid var(--brown-color);
  border-bottom: 2px solid var(--brown-color);
  border-right: 2px solid var(--brown-color);
  border-radius: 0;
}
.ranking-table thead th:first-child{
  border-left: 2px solid var(--brown-color);
  border-top: 2px solid var(--brown-color);
  border-bottom: 2px solid var(--brown-color);
  border-radius: 20px 0 0 20px;
}
.ranking-table thead th:last-child{
  border-right: 2px solid var(--brown-color);
  border-top: 2px solid var(--brown-color);
  border-bottom: 2px solid var(--brown-color);
  border-radius: 0 20px 20px 0;
}

.ranking-table tbody td{
  border-right: 2px solid var(--brown-color);
  border-bottom: 0;
  text-align: center;
}
.ranking-table tbody td:last-child{
  border-right: 0;
}
.ranking-table tr.highlighted{
  color: #e66346;
}
.ranking-table tr .medal{
  max-width: 22px;
}
</style>
