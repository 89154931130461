import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'

import 'bootstrap'
import './assets/style.scss'
import './assets/custom.css'

const app = createApp(App)

axios.defaults.baseURL = process.env.VUE_APP_API_PATH;
axios.defaults.withCredentials = true
app.config.globalProperties.$axios = axios
app.config.globalProperties.$myApiKey = process.env.VUE_APP_API_MY_KEY
axios.defaults.headers.common = {
    'TestHash' : process.env.VUE_APP_API_MY_KEY,
    'Authorization': `Bearer ${localStorage.getItem('sez_session_hash')}`
}

app.use(router,axios)
app.mount('#app')
