<template>
  <header>
    <div class="container d-flex justify-content-between">
      <img class="logo" src="../assets/images/logo.svg" alt="Sár és tűz">
      <div v-if="status==='question'" class="center-display">
        <div class="row parameters">
          <div class="col-4">
            <div class="value p1">{{parameter_1}}%</div>
            <div class="title">Becsület</div>
          </div>
          <div class="col-4">
            <div class="value p2">{{parameter_2}}%</div>
            <div class="title">Közösségi megítélés</div>
          </div>
          <div class="col-4">
            <div class="value p3">{{parameter_3}}%</div>
            <div class="title">Vagyon</div>
          </div>
        </div>
      </div>
      <div v-if="status==='question'" class="timeline">
        <div class="year">{{timeLineYear}}</div>
        <div class="month">{{timeLineLabel}}</div>
      </div>
      <div v-if="status==='question'" class="row counter">
        <div class="col-10">
          <div class="line">
            <div class="background" :style="'width: '+counterPercent+'%;'"></div>
          </div>
        </div>
        <div class="col-2 text">{{counterNow}}/{{counterAll}} kérdés</div>
      </div>
    </div>
    <div v-if="audioMuted===true" class="audio-control muted" @click="mute()"></div>
    <div v-else class="audio-control un-muted" @click="mute()"></div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: ['initData'],
  data() {
    return {
      p1Block : null,
      p2Block : null,
      p3Block : null
    }
  },
  mounted(){
    this.p1Block = document.querySelector('.parameters .p1')
    this.p2Block = document.querySelector('.parameters .p2')
    this.p3Block = document.querySelector('.parameters .p3')
  },
  computed:{
    status(){
      return this.initData.user.status
    },
    audioMuted(){
      return this.initData.audio.muted
    },
    counterAll(){
      return this.initData.user.counter.all
    },
    counterNow(){
      return this.initData.user.counter.now
    },
    counterPercent(){
      return (this.counterNow / this.counterAll)*100
    },
    timeLineYear(){
      let questionData = this.initData.user.questionData
      if(questionData!==null && questionData.hasOwnProperty('timeLineYear')){
        return this.initData.user.questionData.timeLineYear
      }
        return null
    },
    timeLineLabel(){
      let questionData = this.initData.user.questionData
      if(questionData!==null && questionData.hasOwnProperty('timeLineLabel_hu')){
        return this.initData.user.questionData.timeLineLabel_hu
      }
      return null
    },
    parameter_1(){
      let parameters = this.initData.user.parameters
      if(parameters!==null && parameters.hasOwnProperty('p1')){
        return this.initData.user.parameters.p1
      }
      return 0
    },
    parameter_2(){
      let parameters = this.initData.user.parameters
      if(parameters!==null && parameters.hasOwnProperty('p2')){
        return this.initData.user.parameters.p2
      }
      return 0
    },
    parameter_3(){
      let parameters = this.initData.user.parameters
      if(parameters!==null && parameters.hasOwnProperty('p3')){
        return this.initData.user.parameters.p3
      }
      return 0
    }
  },
  watch:{
    parameter_1(newValue,oldValue){
      if(!this.p1Block){
        this.p1Block = document.querySelector('.parameters .p1')
        this.p2Block = document.querySelector('.parameters .p2')
        this.p3Block = document.querySelector('.parameters .p3')
      }
      if(this.p1Block && newValue!==oldValue){
        this.p1Block.classList.add('pulse')
        setTimeout(() => {
          this.p1Block.classList.remove('pulse')
        }, 2500)
      }
    },
    parameter_2(newValue,oldValue){
      if(!this.p1Block){
        this.p1Block = document.querySelector('.parameters .p1')
        this.p2Block = document.querySelector('.parameters .p2')
        this.p3Block = document.querySelector('.parameters .p3')
      }
      if(this.p2Block && newValue!==oldValue){
        this.p2Block.classList.add('pulse')
        setTimeout(() => {
          this.p2Block.classList.remove('pulse')
        }, 2500)
      }
    },
    parameter_3(newValue,oldValue){
      if(!this.p1Block){
        this.p1Block = document.querySelector('.parameters .p1')
        this.p2Block = document.querySelector('.parameters .p2')
        this.p3Block = document.querySelector('.parameters .p3')
      }
      if(this.p3Block && newValue!==oldValue){
        this.p3Block.classList.add('pulse')
        setTimeout(() => {
          this.p3Block.classList.remove('pulse')
        }, 2500)
      }
    },
    audioMuted(){

    }
  },
  methods:{
    mute(){
      let muted = this.initData.audio.muted
      if(muted===false){
        muted = true
      }else{
        muted = false
      }
      this.initData.audio.muted = muted
      localStorage.setItem('sez_audio_muted',muted)
    }
  }
}
</script>
<style scoped>
.container{
  position: relative;
  max-width: 1770px;
  padding: 58px 0 50px 0;
  z-index: 0;
}
.center-display{
  margin-top: -55px;
  margin-left: -140px;
}
.logo{
  width: 215px;
}
.audio-control{
  position: absolute;
  right : 0;
  top: 0;
  width: 30px;
  height: 30px;
}
.audio-control.muted{
  background: url('../assets/images/muted.svg') center center no-repeat;
}
.audio-control.un-muted{
  background: url('../assets/images/unmuted.svg') center center no-repeat;
}
.timeline{
  background: url('../assets/images/timeline_bg.svg') 5px 2px no-repeat;
  padding: 0 1.4rem;
  text-align: center;
}
.counter{
  position: absolute;
  margin: 0 auto;
  width: 765px;
  bottom: 55px;
  left: 0;
  right: 0;
  z-index: 666;
}
.counter .line{
  position: relative;
  height: 9px;
  border: 1px solid #8e684f;
  border-radius: 20px;
  margin-top: 7px;
}
.counter .line .background{
  height: 7px;
  background-color: #8e684f;
  border-radius: 20px;
  transition: all 2s ease-in-out;
}
.counter .line:before,.counter .line:after{
  position: absolute;
  display: block;
  content: '';
  top: -1px;
  width: 25px;
  height: 9px;
  background: url('../assets/images/counter_arrow.svg') center center no-repeat;
}
.counter .line:before{
  left: -25px;
}
.counter .line:after{
  right: -25px;
  transform: rotate(180deg);
}

.counter .text{
  font-size: 0.8rem;
  text-align: left;
}
.timeline .year{
  font-family: 'PTF 75',sans-serif;
  font-size: 1.6rem;
  line-height: 1.6rem;
}
.timeline .month{
  font-family: 'PTF 56',sans-serif;
  font-size: 0.85rem;
  line-height: 0.85rem;
}
.parameters{
  width: 140%;
  text-align: center;
}
.parameters .value{
  font-family: 'Meow Script Regular',sans-serif;
  font-size: 3rem;
  line-height: 3rem;
  color:#e66346;
  transition: all 4s ease-in-out;
}
.parameters :deep(.pulse){
  animation: pulse 4s infinite;
  animation-iteration-count: 1;
  color: red;
}
.parameters .title{
  font-size: 0.666rem;
  line-height: 0.666rem;
}

@keyframes pulse {
  0% {
    color: #000;
  }

  50% {
    color: #8e684f;
    text-shadow: 1px 1px #e66346;
  }

  100% {
    color: #e66346;
  }
}
@media (max-width: 1300px) {
  .center-display{
    display: block!important;
    position: absolute;
    top: 130px;
    margin: 0 auto;
    transform: translate(50%,0);
    right: 55%;
  }
  .counter{
    bottom: -80px;
  }
}
@media (max-width: 768px) {
  .counter{
    width: 100%;
    padding: 0 10px;
  }
}
@media (max-width: 576px) {
  .parameters .col-4{
    padding-right: 0;
    padding-left: 0;
  }
  .parameters .value{
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

</style>