<template>
  <div class="container">
    <div class="row">
      <div class="col-12" v-if="questionImage">
        <transition name="image-fade" mode="out-in">
          <img class="question-image" :src="questionImage" alt="" :key="questionImage"/>
        </transition>
      </div>
      <div class="question-left" :class="{'col-sm-6' : questionData.right_hu!==null,'col-12' : questionData.right_hu===null,'top-padding' : questionImage===false}" v-html="questionData.left_hu"></div>

      <div v-if="questionData.right_hu!==null" class="col-sm-6 col-12 question-right">
        <h1 v-html="questionData.right_hu" :class="getCharacterLength(questionData.right_hu)>80 ? 'small' : ''"></h1>
      </div>

      <template v-if="questionType===1">
        <div class="col-sm-6 col-12"></div>
        <div class="col-sm-6 col-12">
          <div class="answer selected d-none" :data-hash="questionData.answers[0].hash"></div>
          <button class="btn btn-primary bottom-0" style="right: 0" @click="answer">{{questionData.answers[0].answer_hu}}</button>
        </div>

      </template>
      <template v-else-if="questionType===2 || questionType===3">
        <div @click="selectAnswer" class="col-sm-6 col-12 answer" :class="getBg(index)+' mru-'+index" v-for="(answer,index) in questionData.answers" :data-hash="answer.hash" :key="answer.hash">
          <div class="row">
            <div class="col-3">
              <div class="rectangle"></div>
            </div>
            <div class="col-9 text">
              {{answer.answer_hu}}
            </div>
          </div>

        </div>
        <div class="col-12">
          <button class="btn btn-primary" @click="answer">Tovább</button>
        </div>
      </template>

      <template v-else-if="questionType===4">
        <template v-for="(answer,index) in questionData.answers">
          <div class="col-sm-6 col-12">
            <div class="select-answer" :class="getBg(index)" >
              {{answer.answer_hu}}
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <button class="select active d-none" :class="'active-select-'+index" :data-hash="answer.hash" data-id="" @click="deSelect(index)"></button>
            <button class="select" :class="'no-selected-'+index" @click="displaySelects(index)">Válassz ki egyet!</button>
            <button class="select d-none" :class="'select-'+index" v-for="select in questionSelects" @click="activateSelect(index,select.id,select.value)">{{select.value}}</button>
          </div>
        </template>
        <div class="col-sm-6 col-12">

        </div>
        <div class="col-sm-6 col-12 mt-3">
          <button class="btn btn-primary mt-0" @click="answer">Tovább</button>
        </div>
      </template>

      <template v-else-if="questionType===5">
        <div class="col-6 text-end" v-for="answer in questionData.answers">
          <input class="answer-input" type="text" :data-hash="answer.hash" maxlength="4"/> <span class="answer-input-sign">%</span>
        </div>
        <div class="col-6">
          <button class="btn btn-primary mt-0" @click="answer">Tovább</button>
        </div>
      </template>

    </div>
  </div>

</template>

<script>
import {Response} from "@/classes/response";
import {includes} from "core-js/internals/array-includes";

export default {
  name: 'Question',
  props: ['initData'],
  data(){

    return {
      answerBgs : [],
      selectsOut : []
    }
  },
  computed:{
    questionData(){
      this.answerBgs = []
      return this.initData.user.questionData
    },
    questionType(){
      return this.questionData.type
    },
    questionSelects(){
      let selectsOut = this.selectsOut
      return this.questionData.selects.filter(function (el) {return !selectsOut.includes(el.id)})
    },
    questionImage(){
      let image = this.initData.user.questionData.image
      if(image===null){
        return false
      }
      let imagePath = ''
      if(isLocalHost===true){
        imagePath = '/img/questions/'+image
      }else{
        imagePath = '/app/img/questions/'+image
      }
      return imagePath
    },
    questionAudio(){
      // NO QUESTION
      if(this.initData.user.questionData===null || !this.initData.user.questionData.hasOwnProperty('audio')){
        this.initData.audio.play = null

        return false
      }
      // QUESTION AUDIO
      let audioFileName = this.initData.user.questionData.audio
      if(audioFileName===null){
        this.initData.audio.play = null

        return false
      }else{
        let audioSrc = ''
        if(isLocalHost===true){
          audioSrc = 'audio/questions/'+audioFileName
        }else{
          audioSrc = '/app/audio/questions/'+audioFileName
        }
        this.initData.audio.loop = true
        this.initData.audio.play = audioSrc

        return true
      }
    }
  },
  watch:{
    questionAudio(){

    }
  },
  methods:{
    includes,
    deSelect(index){
      let activeSelect = document.querySelector('.active-select-'+index)
      activeSelect.classList.add('d-none')
      activeSelect.innerHTML = ''
      activeSelect.dataset.id = ''
      let noSelected = document.querySelector('.no-selected-'+index)
      noSelected.classList.remove('d-none')
    },
    activateSelect(index,id,value){
      let selects = document.querySelectorAll('.select-'+index)
      for(let i = 0;i<selects.length;i++){
        let select = selects[i]
        select.classList.add('d-none')
      }
      let activeSelect = document.querySelector('.active-select-'+index)
      activeSelect.classList.remove('d-none')
      activeSelect.innerHTML = value
      activeSelect.dataset.id = id
    },
    displaySelects(index){

      if(this.questionData.id===49){
        this.selectsOut = []
        let activeSelects = document.querySelectorAll('.active.select')
        let id1 = 0
        let id2 = 0
        let id3 = 0
        for(let i = 0;i<activeSelects.length;i++){
          let activeSelect = activeSelects[i]
          let activeId = activeSelect.dataset.id

          if(activeId == 1){
            id1++
          }else if(activeId == 2){
            id2++
          }else if(activeId == 3){
            id3++
          }
        }

        if(id1>=2){
          this.selectsOut.push('1')
        }
        if(id2>=2){
          this.selectsOut.push('2')
        }
        if(id3>=2){
          this.selectsOut.push('3')
        }
      }




      let noSelected = document.querySelector('.no-selected-'+index)
      noSelected.classList.add('d-none')
      let selects = document.querySelectorAll('.select-'+index)
      for(let i = 0;i<selects.length;i++){
        let select = selects[i]
        select.classList.remove('d-none')
      }
    },
    selectAnswer(e){
      if(this.questionType===1) {
        return false;
      }else if(this.questionType===2) {
        //RESET
        let answers = document.querySelectorAll('.answer')
        for(let i = 0;i<answers.length;i++) {
          answers[i].classList.remove('selected')
        }
        //SELECT
        let answerElement = e.target

        while (!answerElement.classList.contains('answer')) {
          answerElement = answerElement.parentNode
        }

        answerElement.classList.add('selected')
      }else if(this.questionType===3) {
        //SELECT
        let answerElement = e.target

        while (!answerElement.classList.contains('answer')) {
          answerElement = answerElement.parentNode
        }

        if(answerElement.classList.contains('selected')===true){
          answerElement.classList.remove('selected')
        }else{
          answerElement.classList.add('selected')
        }
      }
    },
    answer(){
      this.initData.loader = true

      let answersHashes = []
      let answersInputs = []

      if(this.questionType===1){
        let answers = document.querySelectorAll('.answer.selected')
        for(let i=0;i<answers.length;i++){
          let answer = answers[i]
          let hash = answer.dataset.hash
          answersHashes.push(hash)
        }
      }else if(this.questionType===2 || this.questionType===3){
        let answers = document.querySelectorAll('.answer.selected')
        for(let i=0;i<answers.length;i++){
          let answer = answers[i]
          let hash = answer.dataset.hash
          answersHashes.push(hash)
        }
      }else if(this.questionType===4){
        let answers = document.querySelectorAll('.select.active')
        for(let i=0;i<answers.length;i++){
          let answer = answers[i]
          let hash = answer.dataset.hash
          let inputValue = answer.dataset.id
          answersHashes.push(hash)
          answersInputs.push(inputValue)
        }
      }else if(this.questionType===5){
        let answers = document.querySelectorAll('.answer-input')
        for(let i=0;i<answers.length;i++){
          let answer = answers[i]
          let hash = answer.dataset.hash
          let inputValue = answer.value
          inputValue = inputValue.replace(',','.')
          answersHashes.push(hash)
          answersInputs.push(inputValue)
        }
      }

      //ANSWER AUDIO
      if(answersHashes.length===1){
        console.log('answer audio')
        //GET AUDIO FILE NAME FROM ANSWERS BY HASH
        let audioFileName = ''
        for(let i=0;i<this.questionData.answers.length;i++){
          let answer = this.questionData.answers[i]
          if(answer.hash===answersHashes[0]){
            audioFileName = answer.audio
          }
        }

        if(audioFileName!==null){
          let audioSrc = ''
          if(isLocalHost===true){
            audioSrc = 'audio/answers/'+audioFileName
          }else{
            audioSrc = '/app/audio/answers/'+audioFileName
          }
          this.initData.audio.loop = false
          this.initData.audio.play = audioSrc
          let duration = 0
          //WAIT FOR DURATION
          setTimeout(() => {
            duration = this.initData.audio.duration
            //WAIT FOR AUDIO
            console.log(duration)
            setTimeout(() => {
              this.postAnswer(answersHashes,answersInputs)
            }, duration*1000)
          }, 500)
        }else{
          this.postAnswer(answersHashes,answersInputs)
        }
      }else{
        this.postAnswer(answersHashes,answersInputs)
      }


    },
    postAnswer(answersHashes,answersInputs){
      let formData = new FormData()
      formData.append('answers_hashes', answersHashes)
      formData.append('answers_inputs', answersInputs)

      this.$axios.post('/api/v1/user/answer',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              window.scrollTo(0,0);

              this.initData.user.status = responseData.data.status
              if(this.initData.user.status==='story-end'){
                console.log('story end')
                this.initData.user.ranking = responseData.data.ranking
                this.initData.user.fullRanking = responseData.data.fullRanking

              }else{
                this.initData.user.questionData = responseData.data.questionData
                this.initData.user.counter = responseData.data.counter
              }
              this.initData.user.parameters = responseData.data.parameters
              this.initData.user.otherParameters = responseData.data.otherParameters

            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    },
    getBg(index){
      let rndInt = Math.floor(Math.random() * 8) + 1

      return 'bg-'+rndInt
    },
    getCharacterLength(str) {
      //console.log([...str].length)
      return [...str].length
    }
  }
}
</script>
<style scoped>
.container{
  max-width: 765px;
  padding-bottom: 160px;
}
.question-left{
  font-family: 'PTF 75',sans-serif;
  line-height: 1.2rem;
  text-align: right;
}

.question-right h1::v-deep(small){
  font-size: 1rem;
  line-height: 1.2rem;
}

.question-image{
  width: 100%;
  height: 450px;
  margin-bottom:2rem;
}

.image-fade-enter-active{
  transition: opacity 2s ease-in-out;
}

.image-fade-enter-from{
  opacity: 0;
}

img.image-fade-enter-to{
  opacity: 1;
}

.btn-primary{
  float: right;
  padding: 0 0.75rem;
}

.btn-primary::after{
  content: '';
  width: 30px;
  height: 30px;
  background-image: url('../assets/images/arrow.svg');
  background-repeat: no-repeat;
  background-position: center right;
  transform: rotate(180deg);
}
.select-answer{
  text-align: right;
  font-family: 'PTF 75',sans-serif;
  font-size: 0.875rem;
  padding: 3px 1rem 3px 0;
  margin-bottom: 0.5rem;
}
.select{
  margin: 0 5px 5px 0;
  border: 6px solid #ede0c7;
  border-radius: 20px;
  background: none;
  color: var(--brown-color);
  font-family: 'PTF 75',sans-serif;
  font-size: 0.75rem;
}
.select:hover,.select.active{
  background-color: #ede0c7;
}
.answer{
  cursor: pointer;
  padding: 30px;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #8e684f;
  margin-bottom: 1rem;
}
.answer .rectangle{
  width: 80px;
  height: 65px;
  border: 3px solid #cecdc8;
}
.answer .text{
  display: flex;
  align-items: center;
}
.answer.selected .rectangle{
  background: url('../assets/images/answer_x.svg') center center no-repeat;
  background-size: 100% 100%, auto;
}
.answer-input{
  max-width: 200px;
  background: none;
  border: 6px solid #ede0c7;
  border-radius: 20px;
  text-align: right;
  padding-right: 0.5rem;
}
.answer-input:focus-visible{
  outline: -webkit-focus-ring-color auto 0;
}
.answer-input-sign{
  font-family: 'Meow Script Regular',sans-serif;
  font-size: 2rem;
  line-height: 5rem;
  vertical-align: sub;
}
/* purgecss start ignore */
.mru-0,.mru-2,.mru-4{
  margin: 0 10px 1rem -10px;
}
.mru-1,.mru-3,.mru-5{
  margin: 0 -10px 1rem 10px;
}
.bg-1{
  background: url('../assets/images/answers_bg/1.png') center center no-repeat;
  background-size: cover;
}
.bg-2{
  background: url('../assets/images/answers_bg/2.png') center center no-repeat;
  background-size: cover;
}
.bg-3{
  background: url('../assets/images/answers_bg/3.png') center center no-repeat;
  background-size: cover;
}
.bg-4{
  background: url('../assets/images/answers_bg/4.png') center center no-repeat;
  background-size: cover;
}
.bg-5{
  background: url('../assets/images/answers_bg/5.png') center center no-repeat;
  background-size: cover;
}
.bg-6{
  background: url('../assets/images/answers_bg/6.png') center center no-repeat;
  background-size: cover;
}
.bg-7{
  background: url('../assets/images/answers_bg/7.png') center center no-repeat;
  background-size: cover;
}
.bg-8{
  background: url('../assets/images/answers_bg/8.png') center center no-repeat;
  background-size: cover;
}
/* purgecss end ignore */
@media (max-width: 1300px) {
  .question-image{
    padding-top: 120px;
  }
  .question-left.top-padding{
    padding-top: 120px;
  }
}
@media (max-width: 576px) {
  .question-left{
    text-align: justify;
  }
  /* purgecss start ignore */
  .mru-0,.mru-2,.mru-4{
    margin: 0 10px 1rem 0;
  }
  .mru-1,.mru-3,.mru-5{
    margin: 0 -10px 1rem 0;
  }
  /* purgecss end ignore */
}
</style>
