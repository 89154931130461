<template>
  <Head :initData = "initData"></Head>
  <Header v-if="initData.initLoader===false" :initData = "initData"></Header>
  <Loader :initData="initData"/>
  <InitLoader :initData="initData"/>
  <div class="alert-deck">
    <Alerts :alerts="alert" v-for="alert in initData.alerts"/>
  </div>
  <router-view v-if="initData.initLoader===false" :initData = "initData"/>
  <Footer v-if="initData.initLoader===false"></Footer>
  <Audio v-if="initData.initLoader===false" :initData = "initData"></Audio>
</template>

<script>
import {reactive} from 'vue'
import Loader from "./components/Loader"
import InitLoader from "./components/InitLoader"
import Alerts from "./components/Alerts"
import Head from './components/Head'
import Header from "./components/Header"
import Footer from "./components/Footer"
import {Response} from '@/classes/response.js'
import Audio from "@/components/Audio.vue";

export default {
  name: 'App',
  components: {
    Audio,
    InitLoader, Loader, Alerts, Head, Header, Footer
  },
  data(){
    console.log(typeof localStorage.getItem('sez_audio_muted'))
    // DEFAULT INIT DATA
    const initData = reactive({
      'user' : null,
      'initLoader' : true,
      'initFinished' : false,
      'loader' : false,
      'alerts' : [],
      'lang' : null,
      'audio' : {
        'play' : null,
        'src' : null,
        'muted' : JSON.parse(localStorage.getItem('sez_audio_muted')) || false,
        'loop' : true,
        'duration' : 0
      }
    })

    return {
      initData
    }
  },
  created(){
    this.userInit()
  },
  methods:{
    userInit(){
      this.$axios.get('/api/v1/user/')
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              console.log(responseData)
              this.initData.lang = responseData.data.lang
              this.initData.user = responseData.data.user
              this.initData.properties = responseData.data.properties
              this.initData.characters = responseData.data.characters
              this.initData.parameterLabels = responseData.data.parameterLabels
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.initFinished = true
          });

    }
  }
}
</script>
<style>

</style>
