<template>
  <div class="d-flex guidance">
    <p v-if="step===1" class="d-flex align-items-center">A szabadságharc alatt ingoványossá válik a talaj és bizonytalanná a jövő. Nem tudod, melyik döntésed hogyan hat a bizonytalan jövőre.</p>
    <p v-else class="d-flex align-items-center">A forradalom tüze lángra lobbantotta az egész országot.</p>
    <img class="hand" src="../assets/images/hand.svg" alt="kéz"/>
    <h1 v-if="step===1" class="d-flex align-items-center">Mit szeretnél elkerülni?</h1>
    <h1 v-else class="d-flex align-items-center">Válassz egy kártyát, amiért küzdenél!</h1>
  </div>
  <div class="container">
    <div class="card-holder">
      <div class="card" v-for="property in properties" :data-id="property.id" @click="selectCard" :key="property.id">
        <img class="card-side front" :src="imagePath+property.id+'.png'" alt=""/>
        <img class="card-side back" src="../assets/images/cards/x.png" alt="Kártya hátsó része" />
      </div>
    </div>
    <button class="btn btn-primary" v-if="selectedCard" @click="next">Tovább</button>
  </div>

</template>

<script>
import {Response} from "@/classes/response";

export default {
  name: 'FirstStep',
  props: ['initData'],
  data(){
    let imagePath = ''
    if(isLocalHost===true){
      imagePath = '/img/properties/'
    }else{
      imagePath = '/app/img/properties/'
    }

    return {
      selectedCard : false,
      imagePath
    }
  },
  mounted() {
    this.cardAnimation()
  },
  updated() {
    this.cardAnimation()
  },
  computed:{
    step(){

      return this.initData.user.firstStep.step
    },
    properties(){
      let properties = this.initData.properties
      let step = this.step
      return properties.filter(function (el) {return el.step == step})
    }
  },
  watch:{
    step(){
      let cardElements = document.querySelectorAll('.card')
      for(let i = 0;i<cardElements.length;i++){
        cardElements[i].classList.remove('selected')
      }
    }
  },
  methods:{
    selectCard(e){
      //RESET ALL CARD
      let cardElements = document.querySelectorAll('.card')
      for(let i = 0;i<cardElements.length;i++){
        cardElements[i].classList.remove('selected')
      }
      //SELECT OR UNSELECT CARD
      let cardElement =  e.target

      while(!cardElement.classList.contains('card')){
        cardElement = cardElement.parentNode
      }

      cardElement.classList.add('selected')
      this.selectedCard = true
    },
    next(){
      let selectedId = null
      let cardElements = document.querySelectorAll('.card')
      for(let i = 0;i<cardElements.length;i++){
        let cardElement = cardElements[i]
        if(cardElement.classList.contains('selected')){
          selectedId = cardElement.dataset.id
        }
      }

      let formData = new FormData()
      formData.append('card_id', selectedId)
      formData.append('step', this.step)

      this.$axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem('sez_session_hash')}`

      this.initData.loader = true

      this.$axios.post('/api/v1/user/firstStep',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              window.scrollTo(0,0);

              console.log(responseData)
              this.initData.user.status = responseData.data.status
              let nextStep = responseData.data.nextStep
              this.initData.user.firstStep.step = nextStep
              this.initData.user.properties = responseData.data.properties
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    },
    cardAnimation(){
      let cards = document.querySelectorAll('.card')
      setTimeout(() => {
        let cardLength = cards.length
        for(let i = 0; i<cardLength;i++){
          let card = cards[i]
          if(card.classList.contains('reverse')===true){
            continue
          }else{
            card.classList.add('reverse')
            if(i<(cardLength-1)){
              this.cardAnimation()
              break
            }
          }
        }
      }, 400)
    }
  }
}
</script>
<style scoped>
.container{
  max-width: 1460px;
  padding-bottom: 300px;
}
.card-holder{
  display: flex;
  column-gap: 22px;
}
.guidance{
  column-gap: 16px;
  justify-content: center;
  margin: -140px auto 116px;
}
.guidance h1{
  max-width: 370px;
 
}
.guidance p{
  max-width: 345px;
  padding-top: 8px;
  font-family: 'PFD Bold',sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  text-align: end;
}
.hand{
  width:110px;
}

.btn-primary{
  float: right;
  margin-top: 3rem;
  padding: 0 0.75rem;
}

.btn-primary::after{
  content: '';
  width: 30px;
  height: 30px;
  background-image: url('../assets/images/arrow.svg');
  background-repeat: no-repeat;
  background-position: center right;
  transform: rotate(180deg);
}

.card{
  border-radius: 26px;
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  top: 0;
  transition: all 0.8s ease;
}

.card.selected{
  top: -30px;
}

.card-side {
  border-radius: 10%;
  transition: all 0.8s ease;
  backface-visibility: hidden;
  box-shadow: 16px 16px 22px 4px rgba(69, 119, 24, 0.35);
}

.card.selected .card-side.front,.card:hover .card-side.front{
  box-shadow: 16px 16px 22px 4px rgb(230, 99, 70, 0.8);
}

.card-side.back{
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
}

.card-side.front {
  transform: rotateY(-180deg);
}

.card.reverse .card-side.back {
  transform: rotateY(180deg);
}

.card.reverse .card-side.front {
  transform: rotateY(0deg);
}
@media (max-width: 1300px) {
  .guidance{
    justify-content: right;
  }
  .guidance .align-items-center{
    padding-right: 1rem;
  }
}
@media (max-width: 992px) {
  .guidance{
    margin: 0 auto 70px;
    justify-content: center;
  }
  .guidance .align-items-center{
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .guidance.d-flex{
    display: block!important;
  }
  .guidance .align-items-center{
    display: block!important;
    width: 100%;
    max-width: 100%;
    padding: 0 1rem;
    text-align: justify;
  }
  .guidance .hand{
    display: none;
  }
}
@media (max-width: 576px) {
  .card-holder{
    display: block!important;
  }
  .card{
    width: auto;
    max-width: 400px;
    margin: 0 auto 2rem auto;
  }
  .card-side{
    width: 100%;
    max-width: 400px;
  }
  .card-side.back{
    max-width: 400px;
  }
}
</style>
