<template>
  <video :status="initLoaderVisible" v-if="initLoaderVisible" preload="auto" muted width="620" id="introVideoBlock" autoplay>
    <source
        :src="introVideo"
        type="video/webm"
    />
    Your browser does not support the video tag.
  </video>
</template>

<script>
import introVideo from '@/assets/videos/intro.webm'

export default {
  name: 'InitLoader',
  props: ['initData'],
  data(){
    let interval = setInterval(this.stop, 2300);
    let initLoaderVisible = true

   return{
     initLoaderVisible,
     introVideo,
     interval
   }
  },
  computed: {
    initLoader(){
      return this.initData.initLoader

    },
    initFinished(){
      return this.initData.initFinished
    }
  },
  watch:{
    initLoader(){
      this.hide()
    },
    initFinished(){
      this.hide()
    }
  },
  methods:{
    stop(){
      clearInterval(this.interval);
      this.initData.initLoader = false
    },
    hide(){
      if(this.initLoader===false && this.initFinished===true){
        this.initLoaderVisible = false
      }
    }
  }
}
</script>

<style scoped>
video{
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  margin: auto 0;
}
</style>