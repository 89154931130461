<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-12 welcome-video">
        <video preload="auto" muted width="620" id="welcomeVideoBlock" autoplay>
          <source
              :src="welcomeVideo"
              type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="col-lg-4 col-12 welcome-text">
<!--        <h1>Online kalandjáték az 1848-49-es szabadságharc eseményei alapján</h1>-->
<!--        <h2>Válaszd a hozzád illő karaktert!</h2>-->
        <div class="text-holder">
          <p><strong>1848-ban a történelem felforgatta a mindennapokat.</strong> Személyes döntések sora határozta meg egy család, város vagy akár a nemzet sorsát.</p>
          <p><strong>Hozz döntéseket és viseld a következményeit a Tisza menti kisváros, Csongrád lakójaként!</strong> Hős leszel vagy túlélő? A család vagy nemzet szabadságának védője? Megbecsült vagy becsületes?</p>
          <p>Válaszolj 2 kérdésre, aztán válassz karaktert és alakítsd a sorsod!</p>
        </div>
        <button type="button" @click="start" class="btn btn-primary">Játszom!</button>
      </div>
    </div>
  </div>
</template>

<script>
import {Response} from "@/classes/response";
import welcomeVideo from '@/assets/videos/map.webm'

export default {
  name: 'Welcome',
  props: ['initData'],
  data(){
    return{
      welcomeVideo
    }
  },
  methods:{
    start(){
      this.initData.loader = true

      this.$axios.post('/api/v1/user/start')
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              console.log(responseData)
              this.initData.user.status = responseData.data.status
              localStorage.setItem('sez_session_hash',responseData.data.sessionHash)

              let audioSrc = ''
              if(isLocalHost===true){
                audioSrc = 'audio/welcome/Violin_Huasteco.mp3'
              }else{
                audioSrc = '/app/audio/welcome/Violin_Huasteco.mp3'
              }

              this.initData.audio.loop = true
              this.initData.audio.play = audioSrc
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }

}
</script>
<style scoped>
.container{
  max-width: 1650px;
  padding-bottom: 140px;
}
h1{
  border-top: 2px solid var(--brown-color);
  border-bottom: 2px solid var(--brown-color);
  margin-bottom: 38px;
  padding: 24px 0;
}
h2{
  margin-bottom: 38px;
  font-size: 1.7rem;
  line-height: 1.7rem;
  font-family: 'PFD Bold',sans-serif;
  color: var(--brown-color);
}
.text-holder{
  margin-bottom: 38px;
}
.welcome-video{
  padding-right: 1.5rem;
}
.welcome-text{
  padding-left: 1.5rem;
}
.btn-primary::before{
  content: '';
  background:url(../assets/images/pattern.svg);
  width: 24px;
  height: 30px;
  display: block;
  margin-right:1rem;
}
.btn-primary::after{
  content: '';
  background:url(../assets/images/pattern.svg);
  width: 24px;
  height: 30px;
  display: block;
  transform: rotate(180deg);
  margin-left:1rem;
}
.btn-primary:hover::before, .btn-primary:hover::after{
  filter: brightness(3);
}
video{
  width: 100%;
  height: 100%;
  margin: auto 0;
}
@media (max-width: 992px) {
  .welcome-video{
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    margin-bottom: 2rem;
  }
  .welcome-text{
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
</style>
